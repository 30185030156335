import './Section3.css';

function Section3({ language }) {
  return (
    <div className="Section3" id="testimonials">
      <div className='titre-section3'>
      {language === 'fr' ? (
          <h1>Des milliers de personnes attendent déjà</h1>        ) : (
          <h1>Thousands of people are already</h1>
        )}
      {language === 'fr' ? (
        <h1> avec impatience l'application mobile</h1>        ) : (
        <h1>looking forward to the mobile application </h1>
      )}
        
      </div>
      <div className='img-section3'>
        <img src="https://cdn-icons-png.freepik.com/512/4596/4596136.png" alt="" />
      </div>
    </div>
  );
}

export default Section3;