import './Section7.css';

function Section7() {
  return (
    <div className="Section7">
      {/* Première section (inclinaison horizontale) */}
      <section className="horizontalSection inifintescroll skewed-horizontal">
        <div className="scroll-horizontal  inverse">
          <div className="scroll-content scroll-content-horizontal">
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span>
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span>
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 
            <span>Actilinks ⚬</span> 

          </div>
        </div> 
      </section>

      {/* Deuxième section (inclinaison verticale) */}
      <section className="orangeSection inifintescroll skewed-vertical">
        <div className="scroll-verticale  inverse">
          <div className="scroll-content scroll-content-vertical">
            <span>Connect Over Favorite Activities! · </span> 
            <span>Connect Over Favorite Activities! · </span> 
            
            
          </div>
        </div> 
      </section>
    </div>
  );
}

export default Section7;
