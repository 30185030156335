import React from 'react';
import './Section1.css';

function Section1({ language }) {
  return (
    <div className="Section1">
      <div className='Section1-left'>
        {language === 'fr' ? (
          <h1>Organisez des activités et rencontrez de nouveaux amis !</h1>
        ) : (
          <h1>Organize activities and meet new friends!</h1>
        )}

        <svg
          version="1.0"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="400px" // Définissez la largeur du SVG sur 300px
          viewBox="0 0 500 90" // Modifiez la valeur de la viewBox pour ajuster la largeur
          xmlSpace="preserve"
        >
          <path className="svgPath" fill="none" stroke="rgba(255, 206, 89, 1)" strokeWidth="10" d="M30.5,60.5c0,0,103-70,772-17"></path>
        </svg>

        {language === 'fr' ? (
          <div className='Btn-Section1'>
          Application mobile bientôt disponible !
        </div>        ) : (
          <div className='Btn-Section1'>
          Mobile application soon available!
        </div>        )}
      </div>
      <div className='Section1-right'>
        <img src="https://www.mmv.fr/images/cms/activite-montagne-ete/randonnee-pedestre-montagne.jpg?frz-v=345" alt="" />
      </div>
    </div>
  );
}

export default Section1;
