import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


function Footer({ language }) {
    return (
    <div className="Footer">
        <div className='left-footer'>
            {language === 'fr' ? (
                <h1>Connectez-vous et planifiez des activités !</h1>
            ) : (
                <h1>Connect & Plan Activities!</h1>
            )}
            {language === 'fr' ? (
                <button>Application mobile bientôt disponible ! </button>
            ) : (
                <button>Mobile App soon available! </button>
            )}
            
            
        </div>
        <div className='right-footer'>
            {language === 'fr' ? (
                <h2>Nous contacter</h2>
            ) : (
                <h2>Contact Us</h2>
            )}
            <a href="mailto:customerservice@actilinks.be"><i className="fas fa-envelope"></i> customerservice@actilinks.be</a>
            <a href="https://www.facebook.com/profile.php?id=61556559564070"><i className="fab fa-facebook-f"></i> Facebook</a>
            <a href="https://www.instagram.com/actilinks?igsh=cjRrOWMxdWFiYm5m&utm_source=qr"><i className="fab fa-instagram"></i> Instagram
</a>
        </div>
    </div>
);
}

export default Footer;


