import './App.css';
import React, { useState } from 'react';
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer'
import Section1 from './components/Section1/Section1'
import Section2 from './components/Section2/Section2'
import Section3 from './components/Section3/Section3'
import Section4 from './components/Section4/Section4'
import Section5 from './components/Section5/Section5'
import Section6 from './components/Section6/Section6'
import Section7 from './components/Section7/Section7'
import Section8 from './components/Section8/Section8'

function App() {
  const [language, setLanguage] = useState('fr'); // Langue par défaut : français

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };
  return (
    <div className="App">
      <Navbar language={language} onLanguageChange={handleLanguageChange}/>
      <Section1 language={language}/>
      <Section2 language={language}/>
      <Section3 language={language} id="testimonials"/>
      <Section4 language={language} id="advantages"/>
      <Section5 language={language}/>
      <Section6 language={language}/>
      <Section7 language={language}/>
      <Section8 language={language} id="faq"/>
      <Footer language={language}/>
    </div>
  );
}

export default App;
