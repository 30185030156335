import './Section5.css';

function Section5({ language }) {
  return (
    <div className="Section5">
      <div className='left-section5'>
        {language === 'fr' ? (
          <h1>Organiser facilement des activités</h1>        ) : (
          <h1>Organize activities easily</h1>
        )}
        {language === 'fr' ? (
          <p>Actilinks favorise les liens communautaires en facilitant l'organisation et la participation à des activités avec des personnes partageant les mêmes idées. Notre plateforme intuitive simplifie le processus de planification, vous permettant de passer moins de temps à coordonner et plus de temps à profiter des activités que vous aimez.</p>
        ) : (
          <p>Actilinks fosters community connections by making it seamless to organize and participate in activities with like-minded individuals. Our intuitive platform simplifies the planning process, allowing you to spend less time coordinating and more time enjoying the activities you love.</p>

        )}
      </div>
      <div className='img-section5'>
        <img src="https://www.beesbusy.com/wp-content/uploads/2019/11/video-decouverte-beesbusy-04-02-1-e1600186618733.png" alt="" />
      </div>
    </div>
  );
}

export default Section5;