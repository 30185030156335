import './Section6.css';

function Section6({ language }) {
  return (
    <div className="Section6">
      <div className='img-section6'>
        <img src="https://blog.essaytigers.com/wp-content/uploads/student-activities-college-847x556.jpg" alt="" />
      </div>
      <div className='right-section6'>
        {language === 'fr' ? (
          <h1>Correspondance personnalisée avec des amis</h1>

        ) : (
          <h1>Personalized friend-matching</h1>
        )}
        {language === 'fr' ? (
          <p>Avec Actilinks, découvrez bien plus que de simples profils - débloquez un monde adapté à vos intérêts en vous connectant avec des individus qui partagent vos passions et votre enthousiasme. Notre algorithme avancé sélectionne vos connexions, vous fait rencontrer de nouveaux amis passionnés par les activités que vous aimez et vous fait vivre des expériences inoubliables - un simple clic suffit pour trouver la compagnie idéale.</p>

        ) : (
          <p>With Actilinks, discover more than just profiles—unlock a world tailored to your interests by connecting with individuals who share your passions and enthusiasm. Our advanced algorithm curates your connections, introducing you to new friends keen for the activities you love, fueling unforgettable experiences—just a click leads to perfect companionship.</p>
        )}
      </div>
    </div>
  );
}

export default Section6;