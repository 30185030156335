import './Section2.css';

function Section2({ language }) {
  return (
    <div className="Section2">
      <div className='cercle-blanc'></div>
      {language === 'fr' ? (
          <div className='titre-section2'>
            <h1>Se connecter, s'organiser et s'amuser ensemble !</h1>
          </div>        ) : (
          <div className='titre-section2'>
            <h1>Connect, Organize, and Enjoy Activities Together!</h1>
          </div>        
      )}
      
      <svg version="1.0" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" viewBox="0 0 396 88" xmlSpace="preserve"> 
        <path class="svgPath2" fill="none" stroke="rgba(245,36,95,1)" strokeWidth="10" d="M11.5,65.5c60.52-50.99,95.7-59.88,104-53c12.95,10.74-25.64,45.22-17,50c9.47,5.25,49.31-48.32,67-38 c12,7,2.06,15.82,17,29c17,15,48.37-9.36,94-15c32.7-4.04,64.14-2.97,105-1"></path> 
      </svg>
      {language === 'fr' ? (
          <div className='para-section2-fr'>
          Actilinks met en relation des personnes partageant les mêmes centres d'intérêt pour des activités communes, ce qui permet de nouer des liens d'amitié de manière transparente et amusante.          </div>        ) : (
          <div className='para-section2-en'>
            Actilinks pairs like-minded individuals for shared activities, making friendship-building seamless and fun.
          </div>     
      )}
      {/* <div className='para-section2'>
        Actilinks pairs like-minded individuals for shared activities, making friendship-building seamless and fun.
      </div> */}
    </div>
  );
}

export default Section2;