import './Section4.css';

function Section4({ language }) {
  return (
    <div id='advantages' className="Section4">
      <div className='img-section4'>
        <img src="https://media1.popsugar-assets.com/files/thumbor/t52MV8lEcpc9HqmsciW18dU_Xeg/675x0:4075x3400/fit-in/2048xorig/filters:format_auto-!!-:strip_icc-!!-/2020/05/12/905/n/45942770/71e985f35ebb0aaddc7ce9.61911369_/i/how-to-use-online-activities-to-connect-with-grandparents.jpg" alt="" />
      </div>
      <div className='right-section4'>
        {language === 'fr' ? (
            <h1>Se connecter grâce à des activités</h1>          ) : (
            <h1>Connect through activities</h1>
        )}

        {language === 'fr' ? (
          <p>Actilinks réunit des personnes partageant les mêmes centres d'intérêt en faisant correspondre les profils sur la base d'activités communes, ce qui permet de créer des liens significatifs et de coordonner facilement des activités communes et de les pratiquer ensemble.</p>
          ) : (
            <p>Actilinks unites like-minded individuals by matching profiles based on shared interests in activities, ensuring meaningful connections and an easy way to coordinate and enjoy common activities together.</p>
        )}
        
      </div>
    </div>
  );
}

export default Section4;