import React, { useEffect,useState } from "react";
import "./Navbar.css"; // Assurez-vous d'avoir un fichier CSS pour les styles

const Navbar = ({ onLanguageChange }) => {
const [isOpen, setIsOpen] = useState(false);
const [language, setLanguage] = useState('fr'); // Par défaut, la langue est le français
const [isScrolled, setIsScrolled] = useState(false);
const [isFixed, setIsFixed] = useState(false);

const toggleMenu = () => {
    const navLinks = document.querySelector('.nav-links');
    navLinks.classList.toggle('nav-active');
};
const toggleLanguage = () => {
    const newLanguage = language === 'fr' ? 'en' : 'fr'; // Alterner entre anglais et français
    setLanguage(newLanguage);
        onLanguageChange(newLanguage); // Passer la nouvelle langue au parent (App)
        setIsOpen(false); // Fermer la liste déroulante après avoir sélectionné la langue
};
useEffect(() => {
    const handleScroll = () => {
        const offset = window.pageYOffset;
        if (offset > 0) {
            setIsFixed(true);
            setIsScrolled(true); // Si le défilement est détecté, définissez isScrolled sur true
    } else {
            setIsFixed(false);
            setIsScrolled(false); // Sinon, définissez isScrolled sur false
        }
    }; window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
        };
}, []);

const scrollToSection = (id) => {
    const navbarHeight = document.querySelector('.Navbar').offsetHeight;
    const section = document.getElementById(id);
    if (section) {
        window.scrollTo({
            top: section.offsetTop - navbarHeight,
            behavior: 'smooth' // Pour un défilement doux
        });
    }
};

    return (
<>
    <nav className={`Navbar ${isFixed ? 'fixed' : ''} ${isScrolled ? 'scrolled' : ''}`} id="navbar">
        <h1 className='titre-nav'>Actilinks</h1>
        <ul className="nav-links">

                <div className='lien-nav'>
                {language === 'fr' ? (
                    <button onClick={() => scrollToSection('testimonials')} href="#testimonials">Témoignages</button>

                ) : (
                    <button onClick={() => scrollToSection('testimonials')} href="#testimonials">Testimonials</button>

                )}

                <button onClick={() => scrollToSection('faq')} href="#Faq">FAQ</button>
                    
                {language === 'fr' ? (
                    <button onClick={() => scrollToSection('advantages')} href="#advantages">Avantages</button>

                ) : (
                    <button onClick={() => scrollToSection('advantages')} href="#advantages">Advantages</button>

                )}

                </div>
            
                <div className='Btn-lan'>
                    <div className="dropdown">
                        <button onClick={() => setIsOpen(!isOpen)} className="dropbtn">
                            {language === 'fr' ? 'Français' : 'English'}
                            <span className="arrow">&#9660;</span> {/* Flèche vers le bas */}
                        </button>
                        {isOpen && (
                            <div className="dropdown-content">
                                <button className="dropbtn" onClick={toggleLanguage}>
                                    {language === 'fr' ? 'English' : 'Français'}
                                </button>

                            </div>
                        )}
                    </div>
                </div>
            
                {language === 'fr' ? (
                    <div className='Btn-Nav'>
                    Application mobile bientôt disponible !
                    </div>
                ) : (
                    <div className='Btn-Nav'>
                    Mobile application soon available !
                    </div>
                )}
    </ul>
        <div class={`burger ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line3"></div>
        </div>
        
    </nav>
</>

);
};

export default Navbar;




