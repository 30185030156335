import React from 'react';
import './Section8.css';

function Section8({ language }) {
  return (
    <div id='faq' className="Section8">
      <div className='top-Section8'>
        {language === 'fr' ? (
          <h1>Questions fréquemment posées</h1>
        ) : (
          <h1>Frequently asked questions</h1>
        )}
      </div>
      <div className='bottom-Section8'>
        <div className='left-bottom-Section8'>
          <div className='titre-left-bottom-Section8'>
          {language === 'fr' ? (
            <h1>Comment Actilinks me met-il en relation avec de nouveaux amis ?</h1>
          ) : (
            <h1>How does Actilinks match me with new friends?</h1>
          )}
            
          </div>
          {language === 'fr' ? (
            <p>
            {/* <div className='hr'></div> */}
            Actilinks utilise un algorithme propriétaire qui prend en compte les informations de votre profil, vos activités d'intérêt et votre niveau d'engagement au sein de la communauté. Cela permet de s'assurer que vous êtes mis en relation avec des personnes partageant les mêmes préférences en matière de loisirs, créant ainsi une base pour des connexions authentiques.
            <div className='hr'></div>
          </p>          ) : (
            <p>
            {/* <div className='hr'></div> */}
                Actilinks uses a proprietary algorithm that takes into account your profile information, activities of interest, and engagement level within the community. This helps to ensure you are matched with like-minded individuals who share similar hobby preferences, creating a basis for genuine connections.
            <div className='hr'></div>
          </p>          )}
          
        </div>
        <div className='img-bottom-Section8'>
          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqegZvkGspfvr4YExGdaYCbe1yUp-420FXW7N8tlz9keKGHeFSY0ytIJF7l4Frbxlb7VA&usqp=CAU" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Section8;
